<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          <div class="row">
            <div class="col-md-6 d-flex justify-content-end">

            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
              <CButton color="success" class="mr-2" @click="createNew">
                Добавить
              </CButton>
              <CButton color="success" @click="$router.push('/phones')">
                Спарсить с телефона
              </CButton>

            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >

            <td slot="action" slot-scope="{ item,index }" class="d-flex">
              <CButton color="danger" @click="deleteItem(item,index)" class="mr-2">
                Удалить
              </CButton>

              <CButton color="warning" @click="changeItem(item,index)" class="mr-2">
                Изменить
              </CButton>

              <CButton color="success" @click="viewItem(item,index)">
                Посмотреть
              </CButton>
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal title="Создать нового клиента" :show.sync="warningModal">
      <CCard>
        <CCardBody>
          <CForm v-on:submit="submitForm()">
            <CInput type="text" v-model="change_traffic.kode" label="Код клиента" horizontal/>
            <CInput type="text" v-model="change_traffic.name" label="ФИО" horizontal/>
            <CInput type="text" v-model="change_traffic.phone1" label="Телефон" horizontal/>
            <CInput type="text" v-model="change_traffic.phone2" label="Телефон 2" horizontal/>
            <CButton color="success" type="submit">
              Сохранить
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CModal>

    <CModal title="Создать нового клиента" :show.sync="changeModal">
      <CCard>
        <CCardBody>
          <CForm v-on:submit="submitChangeForm()">
            <CInput type="text" v-model="change_traffic.kode" label="Код клиента" horizontal/>
            <CInput type="text" v-model="change_traffic.name" label="ФИО" horizontal/>
            <CInput type="text" v-model="change_traffic.phone1" label="Телефон" horizontal/>
            <CInput type="text" v-model="change_traffic.phone2" label="Телефон 2" horizontal/>
            <CButton color="success" type="submit">
              Сохранить
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CModal>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </CRow>
</template>

<script>

const qs = require('qs')
const axios = require('axios')
export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum: 0,
      warning: false,
      success: false,
      alert_message: '',
      changeModal: false,
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      traffic: {},
      change_traffic: {},
      file_path: '',
      fileData: '',
      tableItems: [],
      tableFields: [
        {key: "kode", label: "Код"},
        {key: "name", label: "ФИО"},
        {key: "phone1", label: "Телефон"},
        {key: "phone2", label: "Телефон 2"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    changeItem(item, index) {
      this.changeModal = !this.changeModal;
      this.change_traffic = item;
    },
    submitForm() {
      event.preventDefault();
      let app = this;
      app.tableItems = [];
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/contacts/create',
            data: qs.stringify(this.change_traffic),
          })
          .then(({data}) => {
            app.warningModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
            app.getResults();
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)
      })
    },
    submitChangeForm() {
      event.preventDefault();
      let app = this;
      app.tableItems = [];
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/contacts/update/' + this.change_traffic.id,
            data: qs.stringify(this.change_traffic),
          })
          .then(({data}) => {
            app.changeModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
            app.getResults();
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)
      })
    },

    createNew() {
      this.warningModal = !this.warningModal;
      this.change_traffic = {};
    },
    viewItem(item, index) {
      let app = this;
      this.$router.push('/clients/' + item.code_of_client);
    },
    deleteItem(item, index) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/contacts/delete/' + item.id).then(response => {
        app.tableItems.splice(index, 1);
        app.getResults();
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    getResults() {

      console.log('test');
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/contacts/get').then(response => {
        app.tableItems = response.data.clients;
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },

  },
};
</script>
